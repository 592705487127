import { X } from 'lucide-react';
import { DialogContent } from '@/components/ui/dialog';
import useStoreContext from '@/hooks/useStoreContext';
import ReviewImageCarousel from './ReviewImageCarousel';
import { ReviewMedia } from '@/lib/types/review';

interface ReviewImageDialogProps {
  onClose: () => void;
  initialImageIndex?: number;
  mediaItems: ReviewMedia[];
  rowType: 'image' | 'video';
}

export default function ReviewImageDialog({
  onClose,
  initialImageIndex = 0,
  mediaItems,
  rowType,
}: ReviewImageDialogProps) {
  const store = useStoreContext();
  if (!store) throw new Error('Missing Provider in the tree');

  return (
    <DialogContent
      id="review-modal"
      className={`flex flex-col items-center justify-center rounded-lg pb-4 pt-8
    ${
      rowType === 'video'
        ? 'w-full max-w-[100vw] md:aspect-[9/16] md:max-w-[50vw]'
        : 'w-full max-w-[100vw] md:aspect-square md:max-w-[80vh]'
    }`}
    >
      <div className="absolute right-2 top-2 z-10 flex cursor-pointer rounded-full bg-[#F0F0F099] p-1.5">
        <X
          className="stroke-[3px]"
          onClick={(e) => {
            e.stopPropagation(); // Stop the event from bubbling up
            onClose();
          }}
        />
      </div>
      <div className="relative flex pt-6 md:pt-0">
        <ReviewImageCarousel
          mediaItems={mediaItems}
          rowType={rowType}
          initialImageIndex={initialImageIndex}
        />
      </div>
    </DialogContent>
  );
}
