export default function SeatCoverInstallationButton() {
  return (
    <div className="flex w-[260px] justify-center rounded-lg bg-[#1A1A1A] px-[26.5px] py-[15px] align-middle text-base font-bold uppercase text-white lg:w-[520px] lg:px-[52px] lg:py-[30px] lg:text-3xl lg:font-black">
      <a
        href="/installation-guides/seat-covers/coverland-seat-cover-installation-manual-front-rear.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download Instructions
      </a>
    </div>
  );
}
